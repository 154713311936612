
import { AppRoute } from '@tradeoff-team/common-web';

import { AccountTab } from '../enum/accountTab';
import { CompetitionsTab } from 'enum/competitionsTab';

// ROOT
export const home = AppRoute.create();

// PAGES
export const logout = home.create({ routeKey: 'logout' });
export const dashboard = home.create({ routeKey: 'dashboard' });

// TRADING
export const trading = home.create({ routeKey: 'trading' });

// TRADING ENVIRONMENT
export const tradingEnvironment = trading.create({ routeKey: ':environmentType' });

// TRADING > ACCOUNTS
export const accounts = tradingEnvironment.create({ routeKey: 'accounts' });

// TRADING > ACCOUNTS > ACCOUNT DASHBOARD
export const account = accounts.create({ routeKey: ':accountId' });
export const accountTab = account.create({ routeKey: ':accountTab' });

// TRADING > ACCOUNTS > ACCOUNT INFORMATION PAGE
export const accountInformation = accountTab.create({ accountTab: AccountTab.Information });

// TRADING > ACCOUNTS > ACCOUNT ORDER PAGE
export const accountOrders = accountTab.create({ accountTab: AccountTab.Orders });
export const accountOrderInfo = accountOrders.create({ routeKey: ':orderId' });

// TRADING > ACCOUNTS > ACCOUNT ORDER PAGE
export const accountPositions = accountTab.create({ accountTab: AccountTab.Positions });

// TRADING > ACCOUNTS > ACCOUNT INFORMATION PAGE
export const accountRestrictions = accountTab.create({ accountTab: AccountTab.Restrictions });

// TRADING > ACCOUNTS > ACCOUNT TRADETICKET PAGE
export const accountTradeTicket = accountTab.create({ accountTab: AccountTab.TradeTicket });
export const accountTradeTicketEditor = accountTradeTicket.create({ routeKey: ':orderId' });

// TRADING > ORDERS
export const orders = tradingEnvironment.create({ routeKey: 'orders' });

// TRADING > RISK MANAGEMENT
export const riskManagement = tradingEnvironment.create({ routeKey: 'risk-management' });
export const riskManagementTab = riskManagement.create({ routeKey: ':riskManagementTab' });

// TRADING > RISK MANAGEMENT > RESTRICTIONS
export const restrictions = riskManagement.create({ routeKey: 'restrictions' });

// TRADING COMMON
export const tradingCommon = home.create({ routeKey: 'trading-common' });

// TRADING > SECURITIES
export const securities = tradingCommon.create({ routeKey: 'securities' });

// TRADING > WATCHLISTS
export const watchlists = tradingCommon.create({ routeKey: 'watchlists' });

// TRADING > WATCHLISTS > EDIT
export const watchlistsEdit = watchlists.create({ routeKey: 'edit' });

// TRADING > WATCHLISTS > WATCHLIST INFORMATION PAGE
export const watchlistInfo = watchlistsEdit.create({ routeKey: ':watchlistKey' });


// TRADING > SECURITIES > SECURITY INFORMATION PAGE
export const securityInfo = securities.create({ routeKey: ':securityKey' });

// ACADEMY
export const academy = home.create({ routeKey: 'academy' });

// ACADEMY > COMPETITIONS
export const competitions = academy.create({ routeKey: 'competitions' });
export const competition = academy.create({ routeKey: 'competition' });

// ACADEMY > COMPETITION INFO
export const competitionInfo = competition.create({ routeKey: ':competitionId' });
// ACADEMY > COMPETITION > CLONE
export const competitionClone = competitionInfo.create({ routeKey: 'clone' });


// ACADEMY > COMPETITIONS > TABS
export const competitionsTabs = competitions.create({ routeKey: ':competitionsTab' });

// ACADEMY > COMPETITIONS > ACTIVE TAB
export const competitionsActiveTab = competitionsTabs.create({ competitionsTab: CompetitionsTab.Active });

// ACADEMY > COMPETITIONS > HISTORY TAB
export const competitionsHistoryTab = competitionsTabs.create({ competitionsTab: CompetitionsTab.History });

// ACADEMY > COMPETITIONS > FACTORY TAB
export const competitionFactoryTab = competitionsTabs.create({ competitionsTab: CompetitionsTab.Factory });


// ACADEMY > TEMPLATES
export const templates = academy.create({ routeKey: 'templates' });

export const templatesTab = templates.create({ routeKey: ':templatesTab' });
export const templatesEdit = templatesTab.create({ routeKey: 'edit' });
export const templateInfo = templatesEdit.create({ routeKey: ':templateId?' });

// ACADEMY > SCHEDULES
export const schedules = academy.create({ routeKey: 'schedules' });
export const schedulesEdit = schedules.create({ routeKey: 'edit' });

// ACADEMY > SCHEDULES > SCHEDULE INFORMATION PAGE
export const scheduleInfo = schedulesEdit.create({ routeKey: ':scheduleId?' });

// ACADEMY > CHART
export const chart = academy.create({ routeKey: 'chart' });
export const chartPresets = chart.create({ routeKey: 'presets' });

// ACADEMY > SEASON
export const seasons = academy.create({ routeKey: 'seasons' });

// BROKERAGE
export const brokerage = home.create({ routeKey: 'brokerage' });

// BROKERAGE > PROFILES
export const profiles = brokerage.create({ routeKey: 'profiles' });

// BROKERAGE > PROFILES > PROFILE INFORMATION PAGE
export const profile = profiles.create({ routeKey: ':profileId' });
export const profileTab = profiles.create({ routeKey: ':profileTab' });

// SETTINGS
export const settings = home.create({ routeKey: 'settings' });
export const settingsTab = settings.create({ routeKey: ':settingsTab' });

// SETTINGS > TRADING LIMITATIONS
export const tradingLimitationSecuritySettings = settingsTab.create({ routeKey: ':securityKey' });

// NOTIFICATIONS
export const notifications = home.create({ routeKey: 'notifications' });